import { lazy } from "react";
const SuccessMessage = lazy(() => import("../pages/message"));

export const routeList = [
  {
    name: "Success Message",
    path: "/registration-completed",
    exact: true,
    component: SuccessMessage,
    protected: false,
  },
  {
    name: "Success Message",
    path: "/registration-successful",
    exact: true,
    component: SuccessMessage,
    protected: false,
  },
];
