import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { CustomTheme } from "./helpers/theme";
import AppRouter from "./routes/AppRouter";

function App() {
  return (
    <ChakraProvider theme={CustomTheme}>
      <CSSReset />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
