import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { routeList } from "./routes";

const AppRouter = () => {
  return (
    <Suspense>
      <Routes>
        {routeList.map((route) => {
          const Comp = route.component;
          return route.protected ? (
            <Route>Protected</Route>
          ) : (
            <Route key={route.name} exact={route.exact} path={route.path} element={<Comp />} />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
