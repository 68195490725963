import { extendTheme } from '@chakra-ui/react';

const CC = {
  styles: {
    global: {
      canvas: {
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
      },
    },
  },
  components: {
    Button: {
      sizes: {
        md: {
          fontSize: 'md',
          px: '32px',
          h: '40px',
        },
      },
    },
  },
  fonts: {
    heading:
      '"Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    body: '"Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    // heading:
    //   'K2D,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    // body: 'K2D,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    mono: 'Menlo, monospace',
  },

  fontSizes: {
    xs: '0.65rem',
    sm: '0.85rem',
    md: '.9rem',
    lg: '1rem',
    xl: '1.2rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  colors: {
    primary: {
      50: '#e5e9ff',
      100: '#b7bdfd',
      200: '#8890f7',
      300: '#5964f2',
      400: '#2c39ee',
      500: '#0080FC',
      // 500: '#151fd4',
      600: '#0f18a6',
      700: '#081177',
      800: '#030a49',
      900: '#01021d',
    },
    yellow: {
      50: '#fff9da',
      100: '#ffecad',
      200: '#ffdf7d',
      300: '#ffd34b',
      400: '#ffc61a',
      500: '#e6ac00',
      600: '#b38600',
      700: '#806000',
      800: '#4e3900',
      900: '#1d1300',
    },
    brand: {
      primary: '#0080FC',
      secondary: '#F1B500',
    },
  },
};

export const CustomTheme = extendTheme({ ...CC });
